import { PrismicImage, PrismicRichText } from "./types";

export type TypeContactBlock = {
  contact_block_image: PrismicImage;
  contact_block_title: string;
  contact_block_description: string;
  contact_block_link: string;
};

export type TypeContactOverviewPage = {
  title: string;
  meta_title: string;
  subtitle: PrismicRichText;
  hero_image: PrismicImage;
  page_meta_description: string;
  page_meta_thumbnail: PrismicImage;
  contact_blocks: TypeContactBlock[];
};

export type TypeContactOverviewQueryResult = {
  allPrismicContactOverview: {
    edges: Array<{
      node: { data: TypeContactOverviewPage };
    }>;
  };
};

export const getPage = (
  data: TypeContactOverviewQueryResult
): TypeContactOverviewPage => {
  return data.allPrismicContactOverview.edges[0].node.data;
};

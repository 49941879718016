import { graphql, StaticQuery } from "gatsby";
import React from "react";
import "./contact-overview.scss";
import {
  getPage,
  TypeContactOverviewPage,
  TypeContactOverviewQueryResult,
} from "../../utils/queries/contact_overview";
import {
  PageHeaderCustomV2WhiteWide,
  PageHeaderStandard,
} from "../../components/page-header/page-header";
import Helmet from "react-helmet";
import { PageTitle } from "../../components/title/title";
import AmazeeIoMetadata from "../../components/metadata";
import AmazeePage from "../../components/layout";
import usePreviewData from "../../utils/usePreviewData";

const query = graphql`
  {
    allPrismicContactOverview {
      edges {
        node {
          data {
            page_meta_description
            page_meta_thumbnail {
              alt
              url
            }
            title
            meta_title
            hero_image {
              alt
              url
            }
            subtitle {
              html
              raw
              text
            }
            contact_blocks {
              contact_block_title
              contact_block_description
              contact_block_image {
                alt
                url
              }
              contact_block_link
            }
          }
        }
      }
    }
  }
`;

const ContactOverviewQueryPage = ({
  data,
  location,
}: {
  location: Location;
  data: TypeContactOverviewQueryResult;
}) => {
  const pageData = getPage(data);
  if (!pageData) return null;
  const result = usePreviewData(
    pageData,
    "PrismicPricing",
    true
  ) as TypeContactOverviewPage;

  const title = result.meta_title ? result.meta_title : result.title;

  return (
    <AmazeePage location={location}>
      <div id="contact-us">
        <Helmet>
          <link rel="stylesheet" href="/css/carousel-single.min.css" />
          <link rel="stylesheet" href="/css/carousel-multi.min.css" />
        </Helmet>
        <PageTitle title={title} amazeeOnly={true} />
        <AmazeeIoMetadata
          title={result.title}
          page_meta_description={result.page_meta_description}
          page_meta_thumbnail={result.page_meta_thumbnail}
          location={location}
        />

        <PageHeaderStandard
          component={PageHeaderCustomV2WhiteWide}
          header={result.title}
          image={result.hero_image}
          subheader={result.subtitle}
          withSiteName={false}
          hasSectionDivider={false}
        />

        <section id="contact-blocks">
          <div className="container">
            <div className="row justify-between">
              {result.contact_blocks.map((contact_block, index) => (
                <a
                  key={index}
                  href={contact_block.contact_block_link}
                  target={
                    contact_block.contact_block_link.charAt(0) === "/"
                      ? "_self"
                      : "_blank"
                  }
                  className="col-12 col-lg-4 contact-blocks-wrapper"
                >
                  <img
                    src={contact_block.contact_block_image.url}
                    alt={contact_block.contact_block_image.alt}
                  />
                  <div className="contact-block-title">
                    <h2>{contact_block.contact_block_title}</h2>
                  </div>
                  <div className="contact-block-description">
                    {contact_block.contact_block_description}
                  </div>
                </a>
              ))}
            </div>
          </div>
        </section>
      </div>
    </AmazeePage>
  );
};

const StaticContactOverviewQueryPage: React.FC<{ location: Location }> = ({
  location,
}) => {
  return (
    <StaticQuery
      query={query}
      render={(data) => (
        <ContactOverviewQueryPage location={location} data={data} />
      )}
    />
  );
};
export default StaticContactOverviewQueryPage;
